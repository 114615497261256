'use strict'

###*
 # @ngdoc service
 # @name mundoComponents.factory:ConfirmBox

 # @description

###
angular
  .module 'mundoComponents'
  .factory 'FormDialog', [
    '$mdDialog'
    ($mdDialog) ->
      FormDialog = {}
      FormDialog.showFormDialog = (title, entityManager, entity) ->
        $mdDialog.show
          templateUrl: 'mundo-components/mundo-form-dialog/views/mundo-form-dialog.modal.tpl.html'
          controller: 'MundoFormDialogCtrl'
          controllerAs: 'formDialogCtrl'
          locals:
            title: title
            entityManager: entityManager
            entity: entity

      FormDialog
  ]
